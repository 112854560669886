export const config = {
    paths : {
        frontUrl: 'https://za.lighthub.law/',
        apiBaseUrl: 'https://za.lighthub.law/api/v1/',
        serverPublicUrl: 'https://za.lighthub.law/api/public/', 
    },
    keys:{
        azureClient : '71d3d7a2-14ce-457e-a035-86d20fb65b4b',
        cexClient : '9872390120387',
        stripePK : 'pk_test_51JT0X1GFQkyYR6oOViN9sozwxnygyPfGZvmg2pNXI3oH9OAFXIwxOZlJTJlwBD506u0WGRKgzXbBmPEgPxMh11Td00jbAg1AiE'
    }
}